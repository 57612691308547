/* global Modernizr */
import { TweenMax, TimelineMax } from 'gsap';
import { easeRegular } from '../util/Easing';

import Base from './Base';
import { outerWidth } from '../util/Size';
import constrain from '../util/Constrain';
import Draggable from '../behaviour/Draggable';
import Events from '../util/Events';
import { trackEvent } from '../util/Track';

const selector = '.ifly';

class Init extends Base {
    init() {
        this.ui = {
            list:                   this.el.querySelector('.slider__list'),
            listItems:              this.el.querySelectorAll('.slider__item'),
            paginationDotContainer: this.el.querySelector('.slider__dots'),
            paginationDots:         this.el.querySelectorAll('.slider__dot'),
            iflyButton:             this.el.querySelector('.ifly__button'),
            images:                 this.el.querySelectorAll('img[data-src'),
        };

        this.state = {
            ...this.state,
            index:            0,
            maxIndex:         this.ui.listItems.length - 1,
            length:           this.ui.listItems.length,
            containerWidth:   outerWidth(this.el),
            prevItem:         null,
            nextItem:         null,
            currentItem:      null,
            snapTreshold:     20,
            dragCurrentOutro: null,
            dragNextTL:       null,
            dragPrevTL:       null,
        };

        this.draggable = null;

        if (this.ui.listItems) {
            this.setItemReferences(0);

            for (let i = 0; i < this.ui.listItems.length; i += 1) {
                this.ui.listItems[i].addEventListener('click', this.listItemClickHandler.bind(this));
            }

            for (let i = 0; i < this.ui.paginationDots.length; i += 1) {
                this.ui.paginationDots[i].addEventListener('click', this.paginationDotClickHandler.bind(this));
            }
        }

        if (this.ui.iflyButton) {
            this.ui.iflyButton.addEventListener('click', Init.iflyButtonClick, false);
        }

        if (Modernizr.touchevents) {
            this.setupDrag();
        }
    }

    showIn() {
        super.showIn();

        trackEvent('activate', 'slider', this.ui.listItems[0].querySelector('a.slide__link').getAttribute('title'));

        for (let i = 0; i < this.ui.images.length; i += 1) {
            const image = this.ui.images[i];
            image.src = image.getAttribute('data-src');
        }
    }

    resizeHandler(viewportWidth, viewportHeight) {
        super.resizeHandler(viewportWidth, viewportHeight);
        this.state.containerWidth = outerWidth(this.el);
    }

    setupDrag() {
        this.draggable = new Draggable(this.el);

        // this.draggable.addEventListener('dragEnd', this.dragEndHandler.bind(this));
        // this.draggable.addEventListener('positionChange', this.dragPositionChangeHandler.bind(this));

        Events.listenTo('dragEnd', this.dragEndHandler, this);
        Events.listenTo('positionChange', this.dragPositionChangeHandler, this);

        this.setupDragTimelines();
    }

    setItemReferences(index) {
        this.setCurrentItem(index);
        this.setPrevItem(index);
        this.setNextItem(index);
    }

    setPrevItem(index) {
        if (index > 0) {
            this.state.prevItem = this.ui.listItems[index - 1].classList.add('is-prev');

            if (index > 1) {
                this.ui.listItems[index - 2].classList.add('is-prevprev');
            }
        } else {
            this.state.prevItem = null;
        }
    }

    setNextItem(index) {
        if (index < this.state.maxIndex) {
            this.state.nextItem = this.ui.listItems[index + 1].classList.add('is-next');
        } else {
            this.state.nextItem = null;
        }
    }

    setCurrentItem(index) {
        this.state.currentItem = this.ui.listItems[index].classList.add('is-active');
    }

    paginationDotClickHandler(e) {
        const item  = e.currentTarget;
        const index = Array.from(this.ui.paginationDots).indexOf(item);

        if (index !== this.state.index) {
            e.preventDefault();
            e.stopImmediatePropagation();

            this.to(index);
        }
    }

    listItemClickHandler(e) {
        const item  = e.currentTarget;
        const index = Array.from(this.ui.listItems).indexOf(item);
        const title = item.querySelector('a.slide__link').getAttribute('title');

        if (index !== this.state.index) {
            e.preventDefault();
            e.stopImmediatePropagation();

            this.to(index);

            trackEvent('activate', 'slider', title);
        } else {
            trackEvent('click', 'slider', title);
        }
    }

    resetClasses() {
        for (let i = 0; i < this.ui.listItems.length; i += 1) {
            this.ui.listItems[i].classList.remove('is-prev', 'is-active', 'is-next', 'is-prevprev');
        }

        for (let i = 0; i < this.ui.paginationDots.length; i += 1) {
            this.ui.paginationDots[i].classList.remove('active');
        }
    }

    activatePaginationDots(index) {
        this.ui.paginationDots[index].classList.add('active');
    }

    to(index) {
        this.resetClasses();
        this.setItemReferences(index);
        this.activatePaginationDots(index);
        this.setupDragTimelines();

        this.state.index = index;
    }

    dragEndHandler(e, pointer, vector) {
        if (!vector) return;

        const position = this.draggable.position;
        const isFirst  = this.isFirst();
        const isLast   = this.isLast();

        if (position.x < -this.state.snapTreshold && !isLast) {
            this.snapNext();
        } else if (position.x > this.state.snapTreshold && !isFirst) {
            this.snapPrevious();
        } else {
            if (!this.canDrag(position)) {
                this.draggable.resetPosition(true);
                return;
            }
            this.snapBack(position.x > 0 ? -1 : 1);
        }
    }

    dragPositionChangeHandler(position) {
        if (!this.canDrag(position)) {
            return;
        }

        const containerWidth = this.state.containerWidth;

        this.setItemReferences(this.state.index);

        if (this.state.currentItem) {
            TweenMax.set(this.state.currentItem, { x: position.x, force3D: true });
        }

        if (position.x < 0 && this.state.nextItem) {
            if (this.state.nextItem) {
                TweenMax.set(this.state.nextItem, { x: containerWidth + position.x, force3D: true });
                // Init.setDragTimeline(this.state.dragNextTL, ratio);
            }
        } else if (this.state.prevItem) {
            TweenMax.set(this.state.prevItem, { x: -containerWidth + position.x, force3D: true });
            // Init.setDragTimeline(this.state.dragPrevTL, ratio);
        }
    }

    snapPrevious() {
        this.snapToIndex(this.state.index - 1);
    }

    snapNext() {
        this.snapToIndex(this.state.index + 1);
    }

    snapBack(direction) {
        this.snapToIndex(this.state.index, direction);
    }

    snapToIndex(index, direction = false) {
        index = constrain(index, 0, this.state.maxIndex);

        if (direction === false) {
            direction = index < this.state.index ? -1 : 1;
        }

        const containerWidth = this.state.containerWidth;

        this.state.index = index;

        const tl = new TimelineMax({
            onComplete: (
                () => {
                    this.to(index);
                }
            ),
        });

        if (this.state.currentItem) {
            tl.to(
                this.state.currentItem,
                1,
                {
                    x      : -direction * containerWidth,
                    ease   : easeRegular,
                    force3D: true,
                    clearProps: 'all',
                },
            );
        }

        if (this.state.dragCurrentOutro) {
            tl.to(
                this.state.dragCurrentOutro,
                1,
                {
                    progress: direction === -1 ? 1 : 0,
                    ease: easeRegular,
                },
                0,
            );
        }

        if (direction === -1 && this.state.prevItem) {
            tl.to(
                this.state.prevItem,
                1,
                {
                    x      : 0,
                    ease   : easeRegular,
                    force3D: true,
                    clearProps: 'all',
                },
                0
            );
            if (this.state.dragPrevTL) {
                tl.to(
                    this.state.dragPrevTL,
                    1,
                    {
                        progress: 1,
                        ease: easeRegular,
                    },
                    0
                );
            }
        }

        if (direction === 1 && this.state.nextItem) {
            tl.to(
                this.state.nextItem,
                1,
                {
                    x      : 0,
                    ease   : easeRegular,
                    force3D: true,
                    clearProps: 'all',
                },
                0,
            );
            if (this.state.dragNextTL) {
                tl.to(
                    this.state.dragNextTL,
                    1,
                    {
                        progress: 1,
                        ease: easeRegular,
                    },
                    0
                );
            }
        }

        // set position of Draggable instance, and pass in true for silent parameter
        // this means, a new 'positionChange' event won't be triggered.
        this.draggable.setPosition({
            x: 0,
            y: 0,
        }, true);
    }

    setupDragTimelines() {
        if (!Modernizr.touchevents) {
            return;
        }

        if (this.state.currentItem) {
            this.state.dragCurrentOutro = Init.getItemIntro(this.state.currentItem);
            window.OUTRO = this.state.dragCurrentOutro;

            this.state.dragCurrentOutro.progress(0.5);
        }

        if (this.state.nextItem) {
            this.state.dragNextTL = Init.getItemIntro(this.state.nextItem, 1);
        }

        if (this.state.prevItem) {
            this.state.dragPrevTL = Init.getItemIntro(this.state.prevItem, -1);
        }

        Init.killTL(this.state.dragCurrentOutroLeft);
        Init.killTL(this.state.dragCurrentOutroRight);
        Init.killTL(this.state.dragNextTL);
        Init.killTL(this.state.dragPrevTL);
    }

    static killTL(tl) {
        if (tl) {
            tl.kill();
        }
    }

    isFirst() {
        return this.state.index === 0;
    }

    isLast() {
        return this.state.index === this.state.maxIndex;
    }

    canDrag(position) {
        return !((this.isFirst() && position.x > 0) || (this.isLast() && position.x < 0));
    }

    static setDragTimeline(tl, ratio) {
        if (tl) {
            tl.progress(ratio);
        }
    }

    static getItemIntro(item, direction) {
        const inner   = item.querySelector('.slide__background');
        const header  = item.querySelector('.slide__heading');
        const text    = item.querySelector('.slide__content');
        const tl       = new TimelineMax({ paused: true });

        tl.fromTo(
            inner,
            1,
            {
                x: `${-direction * 50}%`,
            },
            {
                x: '0%',
                force3D: true,
            }
        );

        tl.fromTo(
            header,
            1,
            {
                opacity: 0,
            },
            {
                opacity: 1,
            },
            0
        );

        tl.fromTo(
            text,
            1,
            {
                x: `${-direction * 50}%`,
                y: '-50%',
                opacity: 0,
            },
            {
                x: '0%',
                y: '-50%',
                opacity: 1,
            },
            0
        );

        return tl;
    }

    static getItemOutro(item) {
        const inner   = item.querySelector('.slide__background');
        const header  = item.querySelector('.slide__heading');
        const text    = item.querySelector('.slide__content');
        const tl       = new TimelineMax({ paused: true });
        const duration = 1;

        tl.fromTo(
            inner,
            duration,
            {
                x: '50%',
            },
            {
                x: '0%',
                force3D: true,
            }
        );

        tl.fromTo(
            inner,
            duration,
            {
                x: '0%',
            },
            {
                x: '-50%',
                force3D: true,
            }
        );

        // --- text -----------------------------

        tl.fromTo(
            text,
            duration - 0.1,
            {
                x: '50%',
                y: '-50%',
                opacity: 0,
            },
            {
                x: '0%',
                y: '-50%',
                opacity: 1,
                force3D: true,
            },
            0.1
        );

        tl.fromTo(
            text,
            duration - 0.1,
            {
                x: '0%',
                y: '-50%',
                opacity: 1,
            },
            {
                x: '-50%',
                y: '-50%',
                opacity: 0,
                force3D: true,
            },
            duration + 0.1
        );

        // header -------------------------------

        tl.fromTo(
            header,
            duration * 0.75,
            {
                opacity: 0,
            },
            {
                opacity: 1,
            },
            0.25
        );

        tl.fromTo(
            header,
            duration * 0.75,
            {
                opacity: 1,
            },
            {
                opacity: 0,
            },
            duration + 0.25
        );

        return tl;
    }

    static iflyButtonClick() {
        trackEvent('click', 'slider', 'to iflymagazine.com');
    }
}

export default {
    Init,
    selector,
};
