import Events from '../util/Events';
import { outerWidth } from '../util/Size';

const selector = '.modal';

class Init {
    constructor(el) {
        this.el = el;

        this.state = {
            isOpen: false,
        };


        Events.listenTo('openVideoModal', this.open, this);
        Events.listenTo('closeVideoModal', this.close, this);
    }

    open(currentModalElement) {
        this.state.isOpen = true;

        const videoIframes = currentModalElement.querySelectorAll('iframe');

        if (videoIframes) {
            for (let i = 0; i < videoIframes.length; i += 1) {
                Init.setIframeHeight(outerWidth(videoIframes[i]));

                videoIframes[i].src = videoIframes[i].getAttribute('data-videourl');
            }
        }
    }

    close(currentModalElement) {
        this.state.isOpen = false;

        const videoIframes = currentModalElement.querySelectorAll('iframe');

        if (videoIframes) {
            for (let i = 0; i < videoIframes.length; i += 1) {
                videoIframes[i].src = 'about:blank';
            }
        }
    }

    resizeHandler() {
        if (this.state.isOpen) {
            const videoIframes = document.querySelector('.modal.active').querySelectorAll('iframe');

            if (videoIframes) {
                for (let i = 0; i < videoIframes.length; i += 1) {
                    Init.setIframeHeight(outerWidth(videoIframes[i]));
                }
            }
        }
    }

    static setIframeHeight(width) {
        const videoIframes = document.querySelector('.modal.active').querySelectorAll('iframe');
        const height = width * 0.5625;

        for (let i = 0; i < videoIframes.length; i += 1) {
            videoIframes[0].setAttribute('height', height);
        }
    }
}

export default {
    Init,
    selector,
};
