/* global ga */
import Events from './Events';

export function trackEvent(event, category, label = '') {
    // console.log('track', event, category, label);

    if (document.body.classList.contains('scrolling')) return;

    if (typeof ga !== 'undefined') {
        ga('send', 'event', category, event, label);
    }
}

export function trackPageView(url = '') {
    // console.log('track', event, category, label);

    if (document.body.classList.contains('scrolling')) return;

    if (url.indexOf('modal') < 0) {
        Events.trigger('setCurrentPage', url);
    }

    if (typeof ga !== 'undefined') {
        ga('send', 'pageview', `${document.location.pathname}${url}`);
    }
}
