import { trackPageView } from '../util/Track';
import Events from '../util/Events';

const selector = '.button__modal';

class Init {
    constructor(el) {
        this.el = el;

        this.el.addEventListener('click', this.openModal.bind(this), false);
    }

    openModal() {
        const currentModal = this.el.getAttribute('data-modal');
        const currentModalElement = document.querySelector(`#${currentModal}`);

        trackPageView(currentModal);


        if (currentModalElement) {
            document.body.classList.add('locked');
            currentModalElement.classList.add('active');

            const currentModalImages = document.querySelectorAll(`#${currentModal} .modal__block-img`);

            for (let i = 0; i < currentModalImages.length; i += 1) {
                const currentModalImage = currentModalImages[i];

                if (currentModalImage.src !== currentModalImage.getAttribute('data-src')) {
                    currentModalImage.src = currentModalImage.getAttribute('data-src');
                }
            }

            Events.trigger('openVideoModal', currentModalElement);

            setTimeout(() => {
                currentModalElement.querySelector('.modal__close').classList.add('active');
            }, 1700);
        }
    }
}

export default {
    Init,
    selector,
};
