export function outerHeight(el) {
    let height = el.offsetHeight;
    const style = getComputedStyle(el);
    height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
    return height;
}

export function outerWidth(el) {
    const width = el.offsetWidth;
    // const style = getComputedStyle(el);
    // width += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
    return width;
}

export function offset(el, inner = false) {
    const rect = el.getBoundingClientRect();
    const padding = {
        top: 0,
        left: 0,
    };

    if (inner) {
        const style = getComputedStyle(el);
        padding.top = parseInt(style.paddingTop, 10);
        padding.left = parseInt(style.paddingLeft, 10);
    }

    const scrollTop = Math.max(0, document.querySelector('.scrollable').scrollTop);
    const scrollLeft = Math.max(0, document.querySelector('.scrollable').scrollLeft);
    const windowWidth = window.innerWidth <= 1655 ? window.innerWidth : 1930;

    return {
        left: rect.left + scrollLeft + padding.left,
        top: rect.top + scrollTop + padding.top,
        right: windowWidth - (rect.left + el.offsetWidth),
    };
}
