import Events from './util/Events';

const selector = 'body';

class Init {
    constructor(el) {
        this.el = el;

        Events.trigger('start:intro');
    }
}

export default {
    Init,
    selector,
};
