/* global fbq */

import { trackEvent, trackPageView } from '../util/Track';
import Events from '../util/Events';

const selector = '.modal';

class Init {
    constructor(el) {
        this.el = el;
        this.ui = {
            closeButton: this.el.querySelector('.modal__close'),
            channelLink: this.el.querySelectorAll('.channel__link'),
            shareLink: this.el.querySelectorAll('.share__item'),
        };

        this.state = {
            currentPage: null,
        };

        if (this.ui.closeButton) this.ui.closeButton.addEventListener('click', this.closeModal.bind(this), false);

        if (this.ui.channelLink) {
            for (let i = 0; i < this.ui.channelLink.length; i += 1) {
                this.ui.channelLink[i].addEventListener('click', Init.modalLinkClickHandler, false);
            }
        }

        if (this.ui.shareLink) {
            for (let i = 0; i < this.ui.shareLink.length; i += 1) {
                this.ui.shareLink[i].addEventListener('click', Init.modalLinkClickHandler, false);
            }
        }

        Events.listenTo('setCurrentPage', this.setCurrentPage, this);
    }

    static modalLinkClickHandler(e) {
        trackEvent('click', 'modal', e.currentTarget.getAttribute('title'));
        fbq('trackSingleCustom', '1446480398771907', `Modal link ${e.currentTarget.getAttribute('title')}`);
    }

    closeModal() {
        this.ui.closeButton.classList.remove('active');
        this.el.classList.remove('active');
        document.body.classList.remove('locked');

        Events.trigger('closeVideoModal', this.el);

        trackEvent('close', 'modal', this.el.getAttribute('id'));

        trackPageView(this.state.currentPage);
    }

    setCurrentPage(title) {
        if (!this.state.currentPage) {
            this.state.currentPage = title;
        }
    }
}

export default {
    Init,
    selector,
};
