import Base from './Base';

const selector = '.episodes__intro';

class Init extends Base {

}

export default {
    Init,
    selector,
};
