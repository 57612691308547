import Base from './Base';

const selector = '.home';

class Init extends Base {
    init() {
        this.ui = {
            images: this.el.querySelectorAll('img[data-src]'),
        };
    }

    showIn() {
        super.showIn();

        if (this.ui.episodePlayer) {
            this.ui.episodePlayer.src = `https://widget.spreaker.com/player?episode_id=${this.ui.episodePlayer.id}&amp;theme=light&amp;playlist=false&amp;playlist-continuous=false&amp;autoplay=false&amp;live-autoplay=false&amp;chapters-image=false&amp;episode_image_position=right&amp;hide-logo=true&amp;hide-likes=true&amp;hide-comments=true&amp;hide-sharing=true&amp;hide-download=false`;
        }

        for (let i = 0; i < this.ui.images.length; i += 1) {
            const image = this.ui.images[i];
            image.src = image.getAttribute('data-src');
        }
    }
}

export default {
    Init,
    selector,
};
