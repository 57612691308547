import { offset } from '../util/Size';
import Events from '../util/Events';
import { trackPageView } from '../util/Track';

export default class Base {
    constructor(el, viewportWidth, viewportHeight, scrollTop, scrollTotal) {
        this.el = el;

        this.state = {
            viewportWidth,
            viewportHeight,
            scrollTop,
            scrollTotal,

            top: 0,
            bottom: 0,
            scrollProgress: 0,
            scrollIntroProgress: 0,
            scrollOutroProgress: 0,
            isShown: false,
            isIn: false,
            isOut: false,
            isBeforeOut: false,
            // firstVisit: true,
            activeEpisode: 0,
        };

        if (this.init) this.init();

        Events.listenTo('intro:done', () => {
            this.resizeHandler(viewportWidth, viewportHeight);
            this.scrollHandler(scrollTop, viewportWidth, viewportHeight, scrollTotal);
        }, this);
    }

    // Triggered when element is shown above the fold.
    show() {
        this.state.isShown = true;
    }

    // Triggered when element is not visible on screen.
    hide() {
        this.state.isShown = false;
    }

    // Triggered when element is visible in the viewport after scrolling down.
    showIn() {
        this.state.isIn = true;
        this.el.classList.add('in');

        // if (this.state.firstVisit && this.el.getAttribute('id')) {
        //     this.state.firstVisit = false;
        //     trackEvent('scroll', 'section', this.el.getAttribute('id'));
        // }

        if (this.el.getAttribute('id')) {
            trackPageView(this.el.getAttribute('id'));
        }
    }

    // Triggered when element is not visible in the viewport.
    hideIn() {
        this.state.isIn = false;
        this.el.classList.remove('in');
    }

    // Triggered when bottom of viewport is past top of the element after scrolling up.
    showOut() {
        this.state.isOut = true;
        this.el.classList.add('out');
    }

    // Triggered when bottom of viewport is past top of the element after scrolling down.
    hideOut() {
        this.state.isOut = false;
        this.el.classList.remove('out');
        this.setActiveNav();

        if (this.el.getAttribute('id')) {
            trackPageView(this.el.getAttribute('id'));
        }
    }

    // Triggered when bottom of viewport is past bottom of the element after scrolling up.
    showBeforeOut() {
        this.state.isBeforeOut = true;
        this.el.classList.add('before-out');
        this.setActiveNav();
    }

    // Triggered when bottom of viewport is past bottom of the element after scrolling down.
    hideBeforeOut() {
        this.state.isBeforeOut = false;
        this.el.classList.remove('before-out');
        this.setActiveNav();
    }

    determineScroll() {
        const scrollOffset = this.state.viewportHeight * 0.1;
        const start = this.state.top + scrollOffset;
        const end = this.state.bottom - scrollOffset;
        const isIn = this.state.scrollTop + this.state.viewportHeight > start;
        const isOut = this.state.scrollTop > end;
        const isBeforeOut = this.state.scrollTop + 80 > this.state.top;

        // Calculate the intro and outro progress.
        // The max limits modules larger than the viewport.
        const scrollProgressMax = Math.min(end - start, this.state.viewportHeight);
        this.state.scrollOutroProgress = Math.max(0, Math.min(1, (this.state.scrollTop - start) / scrollProgressMax));
        this.state.scrollIntroProgress = 1 - Math.max(0, Math.min(1, ((this.state.scrollTop + this.state.viewportHeight) - start) / scrollProgressMax));
        this.state.scrollProgress = Math.max(0, Math.min(1, ((1 - this.state.scrollIntroProgress) + this.state.scrollOutroProgress) / 2));

        if (isOut || isIn) {
            if (this.state.isShown) this.hide();
        } else if (!this.state.isShown) {
            this.show();
        }

        if (isIn) {
            if (!this.state.isIn) this.showIn();
        } else if (this.state.isIn) {
            this.hideIn();
        }

        if (isBeforeOut) {
            if (!this.state.isBeforeOut) this.showBeforeOut();
        } else if (this.state.isBeforeOut) {
            this.hideBeforeOut();
        }

        if (isOut) {
            if (!this.state.isOut) this.showOut();
        } else if (this.state.isOut) {
            this.hideOut();
        }
    }

    resizeHandler(viewportWidth, viewportHeight) {
        this.state.viewportWidth = viewportWidth;
        this.state.viewportHeight = viewportHeight;

        this.state.top = offset(this.el).top;
        this.state.bottom = this.el.offsetHeight + this.state.top;

        this.determineScroll();
    }

    scrollHandler(scrollTop, viewportWidth, viewportHeight, scrollTotal) {
        this.state.viewportWidth = viewportWidth;
        this.state.viewportHeight = viewportHeight;
        this.state.scrollTop = scrollTop;
        this.state.scrollTotal = scrollTotal;

        this.determineScroll();
    }

    setActiveNav() {
        if ((this.el.tagName === 'SECTION' && this.el.getAttribute('id')) || this.el.getAttribute('id') === 'episode-6') {
            Events.trigger('activateNav', this.el.getAttribute('id'));
        } else if ((this.el.tagName === 'ARTICLE')) {
            Events.trigger('activateNav', this.el.getAttribute('data-section'));
        }
    }
}
