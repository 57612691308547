import { trackEvent } from '../util/Track';

const selector = '.footer';

class Init {
    constructor(el) {
        this.el = el;
        this.ui = {
            footerLink: this.el.querySelector('.footer__link'),
        };

        if (this.ui.footerLink) {
            for (let i = 0; i < this.ui.footerLink.length; i += 1) {
                this.ui.footerLink[i].addEventListener('click', Init.footerLinkClickHandler, false);
            }
        }
    }

    static footerLinkClickHandler(e) {
        trackEvent('click', 'footer', e.currentTarget.getAttribute('title'));
    }
}

export default {
    Init,
    selector,
};
