import Base from './Base';
import { offset, outerHeight } from '../util/Size';
import Events from '../util/Events';

const selector = '.episodes';

class Init extends Base {
    init() {
        this.state = {
            ...this.state,
            fixedFooter: false,
        };

        this.getElements();
    }

    getElements() {
        this.episodesList = this.el.querySelector('.episodes__list');
        this.footerEl = this.el.querySelector('.episodes__footer');
        const els = this.el.querySelectorAll('.episode');
        this.episodes = [];

        for (let i = 0; i < els.length; i += 1) {
            const index = els[i].dataset.index;
            this.episodes[parseInt(index, 10)] = els[i];
        }

        this.firstEpisode = this.episodes[0];
        this.lastEpisode = this.episodes[this.episodes.length - 1];

        this.firstEpisodeHeader = this.el.querySelectorAll('.episode__header')[0];
    }

    scrollHandler(scrollTop, viewportWidth, viewportHeight, scrollTotal) {
        super.scrollHandler(scrollTop, viewportWidth, viewportHeight, scrollTotal);

        // this.handleEpisodeTriggers(scrollTop, viewportWidth, viewportHeight);
        // this.handleFooterTrigger(scrollTop, viewportWidth, viewportHeight);
    }

    // handleEpisodeTriggers(scrollTop, viewportWidth, viewportHeight) {
    //     const screenCenterY = scrollTop + (((viewportHeight - 275) / 2) + 75);
    //     const elTopY = offset(this.episodes[this.state.activeEpisode]).top;
    //     const elBottomY = offset(this.episodes[this.state.activeEpisode]).top + outerHeight(this.episodes[this.state.activeEpisode]);

    //     if (this.state.activeEpisode < this.episodes.length - 1 && elBottomY <= screenCenterY) {
    //         Events.trigger('newActiveEpisode', this.state.activeEpisode + 1);
    //         this.state.activeEpisode += 1;
    //     } else if (this.state.activeEpisode > 0 && elTopY > screenCenterY) {
    //         Events.trigger('newActiveEpisode', this.state.activeEpisode - 1);
    //         this.state.activeEpisode -= 1;
    //     }
    // }

    handleFooterTrigger(scrollTop, viewportWidth, viewportHeight) {
        const screenBottomY = scrollTop + viewportHeight;
        const firstTriggerY = offset(this.firstEpisode).top + outerHeight(this.firstEpisode) + outerHeight(this.footerEl);
        const lastTriggerY = offset(this.lastEpisode).top + outerHeight(this.lastEpisode) + outerHeight(this.footerEl);
        const staticTopY = (offset(this.firstEpisode).top - offset(this.episodesList).top) + outerHeight(this.firstEpisode);
        const staticBottomY = 0;
        const fixedX = (viewportWidth >= 1655)
            ? (viewportWidth - 1655) / 2
            : this.stickyNavRight = 20;

        console.log(this.state.fixedFooter, screenBottomY, firstTriggerY);

        if (!this.state.fixedFooter
            && screenBottomY < firstTriggerY) {
            console.log('1');
            Events.trigger('footerPosTrigger', `position:absolute;bottom:auto;top:${staticTopY}px;left:0px;`);
            this.state.fixedFooter = true;
        } else if (this.state.fixedFooter
            && screenBottomY >= firstTriggerY && screenBottomY <= lastTriggerY) {
            console.log('2');
            Events.trigger('footerPosTrigger', `position:fixed;bottom:0;top:auto;left:${fixedX}px`);
            this.state.fixedFooter = false;
        } else if (!this.state.fixedFooter
            && screenBottomY > lastTriggerY) {
            console.log('3');
            Events.trigger('footerPosTrigger', `position:absolute;bottom:${staticBottomY}px;top:auto;left:0px;`);
            this.state.fixedFooter = true;
        }
    }
}

export default {
    Init,
    selector,
};
