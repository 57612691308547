import Events from '../util/Events';

/**
 * Virtual Drag
 * Keeps track of distances dragged, but doesn't actually move a DOM element.
 */
export default class Draggable {
    constructor(el) {
        this.el       = el;
        this.position = { x: 0, y: 0 };

        this.initialize();
    }

    static defaults() {
        return {
            direction: 'x',
        };
    }

    static getMoveVector(startTouch, endTouch) {
        return {
            x: endTouch.x - startTouch.x,
            y: endTouch.y - startTouch.y,
        };
    }

    initialize() {
        this.addEventListeners();
    }

    addEventListeners() {
        this.boundStartListener = this.touchStartHandler.bind(this);
        this.boundMoveListener  = this.touchMoveHandler.bind(this);
        this.boundEndListener   = this.touchEndHandler.bind(this);

        this.el.addEventListener('touchstart', this.boundStartListener);
        this.el.addEventListener('touchend', this.boundEndListener);
    }


    static getPosition(e) {
        const touchEvent = e.originalEvent || e;

        if (!touchEvent) return null;

        const touch = touchEvent.changedTouches ? touchEvent.changedTouches[0] : null;

        if (touch.clientX && touch.clientY) {
            return {
                x: touch.clientX,
                y: touch.clientY,
            };
        }
    }

    touchStartHandler(e) {
        this.startTouch = Draggable.getPosition(e);
        this.lastTouch  = this.startTouch;

        document.addEventListener('touchmove', this.boundMoveListener);
    }

    touchMoveHandler(e) {
        const currentTouch   = Draggable.getPosition(e);

        // distance dragged since last move update
        this.lastMoveVector  = Draggable.getMoveVector(this.lastTouch, currentTouch);
        // distance dragged since dragstart
        this.totalMoveVector = Draggable.getMoveVector(this.lastTouch, this.startTouch);

        this.lastTouch       = currentTouch;

        this.movePosition(this.lastMoveVector);

        Events.trigger('dragMove', e, currentTouch, this.lastMoveVector);
    }

    touchEndHandler(e) {
        document.removeEventListener('touchmove', this.boundMoveListener);

        Events.trigger('dragEnd', e, this.lastTouch, this.totalMoveVector);
    }

    movePosition(vector) {
        const position = {
            x: this.position.x + vector.x,
            y: this.position.x + vector.y,
        };

        this.setPosition(position);
    }

    setPosition(position, silent = false) {
        this.position = position;

        if (!silent) {
            Events.trigger('positionChange', position);
        }
    }

    resetPosition(silent = false) {
        this.setPosition({
            x: 0,
            y: 0,
        }, silent);
    }
}
