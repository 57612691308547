import { TweenLite } from 'gsap';
import CustomEase from '../util/CustomEase';
import { offset } from '../util/Size';
import scrollTo from '../util/ScrollTo';
import playSequence from '../util/pngSequence';
import { trackEvent, trackPageView } from '../util/Track';

import Base from './Base';

const selector = '.intro';

class Init extends Base {
    init() {
        this.ui = {
            continueButton: this.el.querySelector('.intro__button-continue'),
            logo: this.el.querySelector('.intro__logo-image'),
            logoPlaceHolder: this.el.querySelector('.intro__logo'),
            content: this.el.querySelector('.intro__content'),
            // button: this.el.querySelector('.intro__button-video'),
            videos: this.el.querySelectorAll('video'),
        };

        this.state = {
            ...this.state,
            isFirstRun: true,
            resizeTimeout: null,
        };

        this.continue = this.continue.bind(this);
        this.animateLogo = this.animateLogo.bind(this);

        if (this.ui.continueButton) this.ui.continueButton.addEventListener('click', this.continue.bind(this), false);

        this.animateLogo();
    }

    continue() {
        const targetSection = document.getElementById('episodes');
        const targetSectionTop = offset(targetSection).top;

        scrollTo(targetSectionTop, targetSection);

        trackEvent('click', 'intro', 'start listen');

        this.state.isFirstRun = false;
    }

    resizeHandler(viewportWidth, viewportHeight) {
        super.resizeHandler(viewportWidth, viewportHeight);
    }

    animateLogo() {
        playSequence(this.ui.logo, 44, 1.4, 0);

        TweenLite.fromTo(this.ui.logoPlaceHolder, 1.4, {
            yPercent: 50,
            y: 40,
        }, {
            yPercent: 50,
            y: 0,
            ease: CustomEase.create('custom', 'M0,0 C0.224,0 0.562,1 1,1'),
        });

        TweenLite.fromTo(this.ui.logoPlaceHolder, 2.52, {
            yPercent: 50,
        }, {
            yPercent: 0,
            delay: 1.4,
            clearProps: 'all',
            ease: CustomEase.create('custom', 'M0,0 C0.376,0 0.135,1 1,1'),
        });

        TweenLite.fromTo(this.ui.content, 2.52, {
            opacity: 0,
            y: 100,
        }, {
            opacity: 1,
            y: 0,
            delay: 1.4,
            ease: CustomEase.create('custom', 'M0,0 C0.376,0 0.135,1 1,1'),
            clearProps: 'y',
            onComplete: trackEvent('animation', 'intro', 'done'),
        });
    }

    showIn() {
        super.showIn();
        trackPageView('intro');
    }

    showOut() {
        super.showOut();

        this.stopVideos();
    }

    hideOut() {
        super.hideOut();

        this.playVideos();
    }

    stopVideos() {
        for (let i = 0; i < this.ui.videos.length; i += 1) {
            this.ui.videos[i].pause();
        }

        // trackEvent('stop videos', 'intro', '');
    }

    playVideos() {
        for (let i = 0; i < this.ui.videos.length; i += 1) {
            this.ui.videos[i].play();
        }

        trackPageView('intro');
    }
}

export default {
    Init,
    selector,
};
