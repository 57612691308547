import Events from '../util/Events';
import { offset } from '../util/Size';
import scrollTo from '../util/ScrollTo';
import { trackEvent } from '../util/Track';

const selector = '.nav';

class Init {
    constructor(el) {
        this.el = el;

        this.ui = {
            menuLink: this.el.querySelectorAll('.menu__link'),
            logo: this.el.querySelector('.nav__logo'),
            languageToggle: this.el.querySelector('.language__current'),
        };

        if (this.ui.menuLink) {
            for (let i = 0; i < this.ui.menuLink.length; i += 1) {
                this.ui.menuLink[i].addEventListener('click', Init.menuLinkClickHandler, false);
            }
        }

        if (this.ui.logo) {
            this.ui.logo.addEventListener('click', Init.logoClickHandler, false);
        }

        if (this.ui.languageToggle) {
            this.ui.languageToggle.addEventListener('click', Init.languageToggle, false);
        }

        Events.listenTo('activateNav', this.activateNav, this);
    }

    static menuLinkClickHandler(e) {
        e.stopPropagation();
        e.preventDefault();

        if (e.currentTarget.classList.contains('active')) return;
        if (e.currentTarget.classList.contains('button__modal')) return;

        const currentLink = e.currentTarget.getAttribute('data-section');
        const targetSection = document.getElementById(currentLink);
        const targetSectionTop = offset(targetSection).top;

        trackEvent('click', 'nav', currentLink);

        scrollTo(targetSectionTop, currentLink);
    }

    static logoClickHandler(e) {
        e.stopPropagation();
        e.preventDefault();

        Events.trigger('activate:intro');

        trackEvent('click', 'nav', 'logo');

        scrollTo(0, 'intro');
    }

    static languageToggle() {
        trackEvent('toggle', 'nav', 'language');
    }

    activateNav(item) {
        if (this.ui.menuLink) {
            for (let i = 0; i < this.ui.menuLink.length; i += 1) {
                this.ui.menuLink[i].classList.remove('active');

                if (this.ui.menuLink[i].getAttribute('data-section') === item) {
                    this.ui.menuLink[i].classList.add('active');
                }
            }
        }
    }
}

export default {
    Init,
    selector,
};
