import { TweenLite, SteppedEase } from 'gsap';

export default function playSequence(el, steps, time, delay = 0) {
    const percentStep = -100 + (100 / steps);

    TweenLite.fromTo(el, time, {
        xPercent: 0,
    }, {
        xPercent: percentStep,
        ease: SteppedEase.config(steps - 1),
        delay,
    });
}
