import { TimelineMax, TweenMax, Power2 } from 'gsap';

import Base from './Base';
import { outerHeight, outerWidth, offset } from '../util/Size';
import constrain from '../util/math/Constrain';
import Events from '../util/Events';
import { trackEvent } from '../util/Track';

const selector = '.episode';

class Init extends Base {
    init() {
        this.ui = {
            episode: this.el.querySelector('.episode__visual'),
            episodeVisual: this.el.querySelector('.episode__visual-content'),
            episodeOverlay: this.el.querySelector('.episode__visual-overlay'),
            episodeOverlayImage: this.el.querySelector('.episode__visual-image.overlay'),
            episodeHeader: this.el.querySelector('.episode__header'),
            episodeBackground: this.el.querySelector('.episode__visual-background'),
            episodeBackgroundImage: this.el.querySelector('.episode__visual-background-image'),
            episodeContent: this.el.querySelector('.episode__content'),
            episodeContentInner: this.el.querySelector('.episode__content-inner'),
            episodeTitles: this.el.querySelector('.episode__headings'),
            episodeHeadingLine: this.el.querySelector('.episode__heading-line'),
            episodeHeading: this.el.querySelector('.episode__heading'),
            button: this.el.querySelector('.button__pill'),
            episodePlayer: this.el.querySelector('iframe'),
            images: this.el.querySelectorAll('img[data-src]'),
        };

        const index = parseInt(this.el.dataset.index, 10);

        this.state = {
            ...this.state,
            episodeHeight: 0,
            episodeVisualWidth: 0,
            episodeTop: 0,
            episodeBottom: 0,
            episodeRight: 0,
            scrollIntro: null,
            triggered: false,
            animated: false,
            index,
        };

        if (this.ui.button) {
            this.ui.button.addEventListener('click', Init.buttonClickHandler, false);
        }
    }

    static buttonClickHandler() {
        trackEvent('click', 'episode-6', 'mail button');
    }

    scrollHandler(scrollTop, viewportWidth, viewportHeight, scrollTotal) {
        super.scrollHandler(scrollTop, viewportWidth, viewportHeight, scrollTotal);

        const screenCenter = scrollTop + (((viewportHeight - 275) / 2) + 75);
        const bottomTrigger = scrollTop + (viewportHeight - ((viewportHeight / 100) * 20));
        // const topTrigger = scrollTop + ((viewportHeight / 100) * 20);
        const elementTop = offset(this.el).top;
        const elementBottom = offset(this.el).top + outerHeight(this.el);

        if (!this.state.triggered
            && bottomTrigger >= elementTop) {
            this.state.triggered = true;
            Events.trigger('newActiveEpisode', this.state.index);
            // this.state.scrollIntro.play();
        } else if (this.state.triggered
            && screenCenter < elementBottom
            && screenCenter > elementTop) {
            // if (!this.state.animated) {
            //     // this.state.scrollIntro.play();
            //     this.state.animated = true;
            // }
            Events.trigger('newActiveEpisode', this.state.index);
        }

        // const screenCenterY = scrollTop + (((viewportHeight - 275) / 2) + 75);
        // const elTopY = offset(this.episodes[this.state.activeEpisode]).top;
        // const elBottomY = offset(this.episodes[this.state.activeEpisode]).top + outerHeight(this.episodes[this.state.activeEpisode]);
        //
        // if (this.state.activeEpisode < this.episodes.length - 1 && elBottomY <= screenCenterY) {
        //     Events.trigger('newActiveEpisode', this.state.activeEpisode + 1);
        //     this.state.activeEpisode += 1;
        // } else if (this.state.activeEpisode > 0 && elTopY > screenCenterY) {
        //     Events.trigger('newActiveEpisode', this.state.activeEpisode - 1);
        //     this.state.activeEpisode -= 1;
        // }
    }

    showIn() {
        super.showIn();

        // if (this.ui.episodePlayer) {
        //     this.ui.episodePlayer.src = `https://player.simplecast.com/${this.ui.episodePlayer.id}?dark=false`;
        // }

        for (let i = 0; i < this.ui.images.length; i += 1) {
            const image = this.ui.images[i];
            image.src = image.getAttribute('data-src');
        }
    }

    resizeHandler(viewportWidth, viewportHeight) {
        super.resizeHandler(viewportWidth, viewportHeight);

        this.state.episodeTop = offset(this.el).top;
        this.state.episodeHeight = outerHeight(this.el);
        this.state.episodeVisualWidth = outerWidth(this.ui.episodeVisual);
        this.state.episodeBottom = this.state.episodeTop + this.state.episodeHeight;
        this.state.episodeRight = offset(this.ui.episodeContent).right;

        if (viewportWidth >= 1024) {
            if (this.el.id !== 'episode-0') {
                TweenMax.set(this.ui.episodeVisual, {
                    height: this.state.episodeVisualWidth,
                });
                TweenMax.set(this.ui.episode, {
                    height: this.state.episodeVisualWidth,
                });
                TweenMax.set(this.ui.episodeBackgroundImage, {
                    height: this.state.episodeVisualWidth + 151,
                });
                TweenMax.set(this.ui.episodeOverlayImage, {
                    height: this.state.episodeVisualWidth + 151,
                    width: this.state.episodeVisualWidth + 151,
                });
            } else {
                TweenMax.set(this.ui.episodeVisual, {
                    height: this.state.episodeVisualWidth + 200,
                });
                TweenMax.set(this.ui.episode, {
                    height: this.state.episodeVisualWidth + 200,
                });
                TweenMax.set(this.ui.episodeBackgroundImage, {
                    height: this.state.episodeVisualWidth + 352,
                });
                TweenMax.set(this.ui.episodeOverlayImage, {
                    height: this.state.episodeVisualWidth + 352,
                    width: this.state.episodeVisualWidth + 352,
                });
            }

            // TweenMax.set(this.ui.episodeOverlay, {
            //     width: this.state.episodeVisualWidth - 60,
            //     height: this.state.episodeVisualWidth - 60,
            //     top: 30,
            //     left: 30,
            // });
        } else {
            // TweenMax.set(this.ui.episodeVisual, {
            //     height: this.state.episodeVisualWidth / 2,
            // });

            // TweenMax.set(this.ui.episodeOverlay, {
            //     width: this.state.episodeVisualWidth - 40,
            //     height: this.state.episodeVisualWidth / 2 - 40,
            //     top: 20,
            //     left: 20,
            // });

            this.ui.episodeVisual.setAttribute('style', '');
            // this.ui.episodeOverlay.setAttribute('style', '');
            this.ui.episode.setAttribute('style', '');
            this.ui.episodeBackgroundImage.setAttribute('style', '');
            this.ui.episodeOverlayImage.setAttribute('style', '');
            this.ui.episodeBackground.setAttribute('style', '');
        }

        // this.setIntro();

        // if (this.state.triggered) {
        //     this.state.scrollIntro.progress(1);
        // }

        Events.trigger('calculateStickyNav', viewportWidth, viewportHeight);
    }

    setIntro() {
        this.state.scrollIntro = new TimelineMax({ paused: true, ease: Power2.easeIn });

        // this.state.scrollIntro.fromTo(this.ui.episodeVisual, 72, {
        //     top: -15,
        // }, {
        //     top: 0,
        // });
        //
        // this.state.scrollIntro.fromTo(this.ui.episodeVisual, 32, {
        //     height: 0,
        // }, {
        //     height: this.state.episodeVisualWidth,
        // }, '-=32');
        //
        // this.state.scrollIntro.fromTo(this.ui.episodeTitles, 32, {
        //     top: 3,
        //     yPercent: 0,
        // }, {
        //     top: '50%',
        //     yPercent: '-50',
        // }, '-=32');

        // this.state.scrollIntro.fromTo(this.ui.episodeOverlay, 28, {
        //     width: this.state.episodeVisualWidth,
        //     height: this.state.episodeVisualWidth,
        //     top: 0,
        //     left: 0,
        // }, {
        //     width: this.state.episodeVisualWidth - 60,
        //     height: this.state.episodeVisualWidth - 60,
        //     top: 30,
        //     left: 30,
        // });

        if (this.ui.episodeHeadingLine) {
            this.state.scrollIntro.fromTo(this.ui.episodeHeadingLine, 28, {
                width: 0,
            }, {
                width: 30,
            }, '-=28');
        }

        // this.state.scrollIntro.fromTo(this.ui.episodeBackground, 72, {
        //     height: 0,
        // }, {
        //     height: this.state.viewportWidth >= 1024 ? this.state.episodeVisualWidth + 80 : this.state.episodeVisualWidth + 40,
        // }, '-=100');

        this.state.scrollIntro.fromTo(this.ui.episodeContent, 32, {
            opacity: 0,
        }, {
            opacity: 1,
        }, '-=32');

        this.state.scrollIntro.fromTo(this.ui.episodeContentInner, 32, {
            x: 50,
        }, {
            x: 0,
        }, '-=32');

        this.state.scrollIntro.fromTo(this.ui.episodeHeading, 32, {
            opacity: 0,
        }, {
            opacity: 1,
        });

        // if (this.ui.episodeHeader) {
        //     this.state.scrollIntro.fromTo(this.ui.episodeHeader, 80, {
        //         top: 15,
        //     }, {
        //         top: 0,
        //     }, '-=32');
        // }

        this.state.scrollIntro.duration(1.5);
    }

    getIntroProgress(elScrollTop) {
        // don't bother doing further calculations when not necessary
        if (elScrollTop > this.state.viewportHeight) {
            return 0;
        }

        if (elScrollTop < 0) {
            return 1;
        }

        const progress = 1 - (elScrollTop / (this.state.viewportHeight * 0.8));

        return constrain(progress, 0, 1);
    }
}

export default {
    Init,
    selector,
};
