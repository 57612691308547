/* global fbq */

import Base from './Base';
import Events from '../util/Events';
import { trackEvent } from '../util/Track';

const selector = '.episodes__footer';

class Init extends Base {
    init() {
        this.state = {
            ...this.state,
        };

        this.ui = {
            footerLink: this.el.querySelectorAll('.episodes__footer-channel a'),
        };

        if (this.ui.footerLink) {
            for (let i = 0; i < this.ui.footerLink.length; i += 1) {
                this.ui.footerLink[i].addEventListener('click', Init.footerLinkClickHandler, false);
            }
        }

        Events.listenTo('footerPosTrigger', this.setFooterPosition, this);
    }

    static footerLinkClickHandler(e) {
        const currentLink = e.currentTarget.getAttribute('title');
        trackEvent('click', 'episodesfooter', currentLink);
        fbq('trackSingleCustom', '1446480398771907', `Episodes footer - ${currentLink}`);
    }

    setFooterPosition(position) {
        this.el.setAttribute('style', position);
    }
}

export default {
    Init,
    selector,
};
