import Base from './Base';
import Events from '../util/Events';
import { offset, outerHeight } from '../util/Size';

const selector = '.episodes__list';

class Init extends Base {
    init() {
        this.state = {
            ...this.state,
            elBottom: offset(this.el).top + outerHeight(this.el),
            stickyToBottom: false,
        };
    }

    scrollHandler(scrollTop, viewportWidth, viewportHeight, scrollTotal) {
        this.state.viewportWidth = viewportWidth;
        this.state.viewportHeight = viewportHeight;
        this.state.scrollTop = scrollTop;
        this.state.scrollTotal = scrollTotal;

        if (scrollTop + viewportHeight > this.state.elBottom) {
            this.state.stickyToBottom = true;
            Events.trigger('stickyTo', (scrollTop + viewportHeight) - this.state.elBottom);
        } else if (this.state.stickyToBottom && scrollTop + viewportHeight < this.state.elBottom) {
            this.state.stickyToBottom = false;
            Events.trigger('removeStickyTo', (scrollTop + viewportHeight) - this.state.elBottom);
        }
    }

    resizeHandler(viewportWidth, viewportHeight) {
        super.resizeHandler(viewportWidth, viewportHeight);
        this.state.elBottom = offset(this.el).top + outerHeight(this.el);
    }
}

export default {
    Init,
    selector,
};
