import CustomEase from './CustomEase';

const easeRegular = CustomEase.create('custom', 'M0,0,C0.37,0,0.15,1,1,1');
const easeExpo = CustomEase.create('custom', 'M0,0,C0.94,0,0.5,1,1,1');
const easeOut = CustomEase.create('custom', 'M0,0,C0.16,0.16,0.67,1,1,1');
const easeIn = CustomEase.create('custom', 'M0,0,C0.37,0,0.15,1,1,1');

export {
    easeRegular,
    easeExpo,
    easeOut,
    easeIn,
};
