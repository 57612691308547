class EventBus {
    constructor() {
        this.bus = document.createElement('div');
    }

    listenTo(name, callback, scope) {
        this.bus.addEventListener(name, (event) => {
            callback.call(scope, ...event.detail);
        }, false);
    }

    trigger(name, ...args) {
        let event;

        if (typeof window.CustomEvent === 'function') {
            event = new CustomEvent(name, { detail: args });
        } else {
            event = document.createEvent('CustomEvent');
            event.initCustomEvent(name, true, true, args);
        }

        this.bus.dispatchEvent(event);
    }
}

export default new EventBus();
