import Base from './Base';

const selector = 'footer';

class Init extends Base {

}

export default {
    Init,
    selector,
};
