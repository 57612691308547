import objectFitImages from 'object-fit-images';
import objectFitVideos from 'object-fit-videos';

import Events from './util/Events';
import { offset } from './util/Size';
import scrollTo from './util/ScrollTo';

import Layout from './Layout';

import IntroComponent from './section/Intro';
import NavComponent from './component/Nav';
import ModalComponent from './component/Modal';
import VideoModalComponent from './component/VideoModal';
import FooterComponent from './component/Footer';
import ModalButtonComponent from './component/ModalButton';

import HomeSection from './section/Home';
import EpisodesSection from './section/Episodes';
import EpisodesIntro from './section/EpisodesIntro';
import EpisodesSidenav from './section/EpisodesSidenav';
import EpisodesFooter from './section/EpisodesFooter';
import EpisodesList from './section/EpisodesList';
import EpisodeSection from './section/Episode';
import IflySection from './section/Ifly';
import FooterSection from './section/Footer';

import '../sass/main.scss';

const modules = [
    IntroComponent,
    NavComponent,
    ModalButtonComponent,
    ModalComponent,
    VideoModalComponent,
    FooterComponent,

    HomeSection,
    EpisodesSection,
    EpisodesIntro,
    EpisodesSidenav,
    EpisodesFooter,
    EpisodesList,
    EpisodeSection,
    IflySection,
    FooterSection,

    // Always load layout last.
    Layout,
];

const instances = [];

let supportsPassiveOption = false;

try {
    const opts = Object.defineProperty({}, 'passive', {
        get: () => {
            supportsPassiveOption = true;
        },
    });
    window.addEventListener('test', null, opts);
} catch (e) {}

(function main() {
    const start = () => {
        let viewportHeight = 0;
        let viewportWidth = document.body.clientWidth;
        let scrollTop = 0;
        let scrollTotal = 0;

        objectFitImages();
        objectFitVideos();

        /**
         * Resize handling
         */
        const resizeHandler = () => {
            viewportWidth = document.body.clientWidth;
            viewportHeight = window.innerHeight;
            scrollTotal = document.querySelector('.scrollable').scrollHeight - viewportHeight;

            instances.forEach((instance) => {
                if (instance.resizeHandler) instance.resizeHandler.call(instance, viewportWidth, viewportHeight);
            }, this);
        };
        window.addEventListener('resize', resizeHandler, supportsPassiveOption ? { passive: true } : false);
        Events.listenTo('trigger:resize', resizeHandler, this);

        /**
         * Scroll handling
         */
        const scrollHandler = () => {
            scrollTop = Math.max(0, document.querySelector('.scrollable').scrollTop);

            instances.forEach((instance) => {
                if (instance.scrollHandler) instance.scrollHandler.call(instance, scrollTop, viewportWidth, viewportHeight, scrollTotal);
            }, this);
        };
        scrollHandler();
        document.querySelector('.scrollable').addEventListener('scroll', scrollHandler, supportsPassiveOption ? { passive: true } : false);

        /**
         * Init modules.
         */
        modules.forEach((Module) => {
            const elements = document.querySelectorAll(Module.selector);

            Array.prototype.forEach.call(elements, (el) => {
                instances.push(new Module.Init(el, viewportWidth, viewportHeight, scrollTop, scrollTotal));
            }, this);
        }, this);

        resizeHandler();

        if (window.location.hash) {
            const targetSection = document.querySelector(window.location.hash);
            const targetSectionTop = offset(targetSection).top;
            scrollTo(targetSectionTop, targetSection.getAttribute.id);
            document.querySelector(window.location.hash).classList.add('in');
        }
    };

    if (document.readyState === 'completed') {
        start();
    } else {
        window.addEventListener('load', start);
    }
}());
