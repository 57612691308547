import { TweenLite } from 'gsap';
import { easeRegular } from './Easing';
import { trackPageView } from './Track';

const DEFAULT_SPEED = 3000;
const menuHeight = 75;

/**
 * Scroll the specified element to the specified y value.
 */
export default function scrollTo(toY, title, speed = DEFAULT_SPEED, ease = easeRegular) {
    const currentY = document.querySelector('.scrollable').scrollTop;
    const distance = Math.abs(toY - currentY);
    const duration = speed > 0 ? Math.max(0, distance / speed) : 0;

    const scrollValue = {
        y: currentY,
    };

    document.body.classList.add('scrolling');

    TweenLite.to(scrollValue, duration, {
        y: toY - menuHeight,
        ease,
        onUpdate: () => {
            document.querySelector('.scrollable').scrollTo(0, scrollValue.y);
        },
        onComplete: () => {
            document.body.classList.remove('scrolling');
            trackPageView(title);
        },
    });
}
