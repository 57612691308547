import Base from './Base';
import Events from '../util/Events';
import { offset, outerHeight } from '../util/Size';
import scrollTo from '../util/ScrollTo';
import { trackEvent } from '../util/Track';

const selector = '.episodes__sidenav';

class Init extends Base {
    init() {
        this.state = {
            ...this.state,
            fixedSidenav: false,
        };

        this.ui = {
            listItems: this.el.querySelectorAll('.episodes__sidenav-index'),
            episodesList: document.querySelector('.episodes__list'),
        };

        if (this.ui.listItems) {
            for (let i = 0; i < this.ui.listItems.length; i += 1) {
                this.ui.listItems[i].addEventListener('click', Init.listItemsClickHandler, false);
            }
        }

        Events.listenTo('newActiveEpisode', this.setActiveEpisode, this);

        Events.listenTo('calculateStickyNav', this.resizeHandler, this);
    }

    static listItemsClickHandler(e) {
        e.stopPropagation();
        e.preventDefault();

        if (e.currentTarget.classList.contains('active')) return;

        const currentLink = e.currentTarget.getAttribute('data-section');
        const targetSection = document.getElementById(currentLink);
        const screenCenterY = ((window.outerHeight - 275) / 2) + 75;
        const targetSectionTop = (offset(targetSection).top - screenCenterY) + (outerHeight(targetSection) / 2);

        trackEvent('click', 'episode-nav', currentLink);

        scrollTo(targetSectionTop, currentLink);
    }

    setActiveEpisode(index) {
        if (index < this.ui.listItems.length) {
            for (let i = 0; i < this.ui.listItems.length; i += 1) {
                this.ui.listItems[i].classList.toggle('active', i === index);
            }
        }
    }

    setTriggerPositions(viewportWidth, viewportHeight) {
        this.stickyNavY = ((viewportHeight / 2) - (outerHeight(this.el) / 2));

        this.stickyNavRight = (viewportWidth >= 1655)
            ? ((viewportWidth - 1655) / 2) + 20
            : 20;

        this.stickyNavTrigger = offset(this.el).top - this.stickyNavY;

        this.staticNavYbottom = this.stickyNavY;

        this.unstickNavTrigger = (outerHeight(this.ui.episodesList) + offset(this.ui.episodesList).top) - viewportHeight;
    }

    resizeHandler(viewportWidth, viewportHeight) {
        super.resizeHandler(viewportWidth, viewportHeight);
        this.setTriggerPositions(viewportWidth, viewportHeight);
    }

    scrollHandler(scrollTop, viewportWidth, viewportHeight, scrollTotal) {
        super.scrollHandler(scrollTop, viewportWidth, viewportHeight, scrollTotal);

        if (!this.state.isIn) return;

        if (!this.state.fixedSidenav
            && scrollTop >= this.stickyNavTrigger
            && scrollTop < this.unstickNavTrigger) {
            this.el.setAttribute('style', `position:fixed; top:${this.stickyNavY - 2}px; right:${this.stickyNavRight}px; z-index: 10`);
            this.state.fixedSidenav = true;
        } else if (this.state.fixedSidenav
            && scrollTop <= this.stickyNavTrigger) {
            this.el.removeAttribute('style');
            this.state.fixedSidenav = false;
        } else if (this.state.fixedSidenav
            && scrollTop >= this.unstickNavTrigger) {
            this.el.removeAttribute('style');
            this.el.setAttribute('style', `position:absolute; bottom: ${this.staticNavYbottom}px; top: auto;`);
            this.state.fixedSidenav = false;
        }
    }
}

export default {
    Init,
    selector,
};
